// import axios from 'axios';
import { hanatourApi } from '../main.js';

export default class NodeService {
    getTreeTableNodes() {
        return hanatourApi.get('assets/demo/data/treetablenodes.json').then(res => res.data.root);
    }

    getTreeNodes() {
        return hanatourApi.get('assets/demo/data/treenodes.json').then(res => res.data.root);
    }
}
